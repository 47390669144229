var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"max-width":"712"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-card-title',{staticClass:"justify-space-between pb-2"},[_c('h2',{staticClass:"text-h2"},[_vm._t("title",function(){return [_vm._v(" Добавление нового клиента ")]})],2),_c('v-btn',{attrs:{"icon":"","small":"","color":"primary darken-3"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask_on_type),expression:"mask_on_type"}],class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_valid_inn(_vm.new_client.organization_type, v) ? _vm.afterValidate(v) : _vm.valid_inn_msg(_vm.new_client.organization_type); }
            ],"label":"ИНН","require":""},model:{value:(_vm.new_client.inn_client),callback:function ($$v) {_vm.$set(_vm.new_client, "inn_client", $$v)},expression:"new_client.inn_client"}}),_c('v-radio-group',{class:[_vm.$style.item, _vm.$style.radios],attrs:{"row":""},model:{value:(_vm.radio_model),callback:function ($$v) {_vm.radio_model=$$v},expression:"radio_model"}},_vm._l((_vm.radios),function(radio,i){return _c('Radio',{key:i,attrs:{"label":radio.label,"value":radio.value}})}),1)],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Название юр.лица / ИП / ФИО физ.лица ","require":"","disabled":_vm.isSearchServiceWorking && _vm.new_client.organization_type != _vm.OrganizationTypes.INDIVIDUAL},model:{value:(_vm.name_organization_model),callback:function ($$v) {_vm.name_organization_model=$$v},expression:"name_organization_model"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.kpp_mask),expression:"kpp_mask"}],key:_vm.new_client.organization_type,class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.kpp_rules_by_type(_vm.new_client.organization_type, v); }
            ],"label":"КПП","require":_vm.is_require_kpp,"disabled":_vm.isSearchServiceWorking && _vm.new_client.organization_type != _vm.OrganizationTypes.INDIVIDUAL},model:{value:(_vm.new_client.kpp_client),callback:function ($$v) {_vm.$set(_vm.new_client, "kpp_client", $$v)},expression:"new_client.kpp_client"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Регион","require":"","blue_icon":"","disabled":_vm.isSearchServiceWorking && _vm.new_client.organization_type != _vm.OrganizationTypes.INDIVIDUAL},model:{value:(_vm.new_client.region),callback:function ($$v) {_vm.$set(_vm.new_client, "region", $$v)},expression:"new_client.region"}}),_c('TextField',{class:_vm.$style.item,attrs:{"label":"Город","rules":[
              function (v) { return _vm.is_require_city(v) || _vm.require_message; }
            ],"blue_icon":"","disabled":_vm.isSearchServiceWorking && _vm.new_client.organization_type != _vm.OrganizationTypes.INDIVIDUAL},model:{value:(_vm.new_client.city),callback:function ($$v) {_vm.$set(_vm.new_client, "city", $$v)},expression:"new_client.city"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"label":"Адрес","disabled":_vm.isSearchServiceWorking && _vm.new_client.organization_type != _vm.OrganizationTypes.INDIVIDUAL},model:{value:(_vm.new_client.address),callback:function ($$v) {_vm.$set(_vm.new_client, "address", $$v)},expression:"new_client.address"}})],1),_c('div',{staticClass:"text-body-1 mb-6"},[_vm._v(" Контактные данные ")]),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"ФИО контактного лица","require":""},model:{value:(_vm.new_client.contact_person),callback:function ($$v) {_vm.$set(_vm.new_client, "contact_person", $$v)},expression:"new_client.contact_person"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_email(v) || _vm.invalid_email; }
            ],"label":"Email","require":""},model:{value:(_vm.new_client.email),callback:function ($$v) {_vm.$set(_vm.new_client, "email", $$v)},expression:"new_client.email"}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],key:_vm.phone_mask_upd,class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"label":"Телефон","require":""},model:{value:(_vm.new_client.telephone),callback:function ($$v) {_vm.$set(_vm.new_client, "telephone", $$v)},expression:"new_client.telephone"}})],1)]),_c('v-card-actions',{staticClass:"modalButtons"},[_c('v-btn',{attrs:{"type":"submit","depressed":"","color":"accent darken-4","disabled":!_vm.form_valid}},[_vm._v(" Добавить нового клиента ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")])],1)],1)],1),_c('SimpleDialog',{attrs:{"max-width":"564","bts_align":"end"},on:{"close":function($event){_vm.info_dialog = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Ошибка ")]},proxy:true},{key:"text",fn:function(){return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.msg_dialog)+" ")])]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.info_dialog = false}}},[_vm._v(" Завершить ")])]},proxy:true}]),model:{value:(_vm.info_dialog),callback:function ($$v) {_vm.info_dialog=$$v},expression:"info_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }